import { addLocaleData } from 'react-intl';

import arLang from './entries/ar_SA';
import deLang from './entries/de_DE';
import enLang from './entries/en-US';
import esLang from './entries/es_ES';
import frLang from './entries/fr_FR';
import heLang from './entries/he_IL';
import itLang from './entries/it_IT';
import jaLang from './entries/ja_JP';
import zhLang from './entries/zh-Hans-CN';

const AppLocale = {
  en: enLang,
  zh: zhLang,
  it: itLang,
  es: esLang,
  fr: frLang,
  ja: jaLang,
  he: heLang,
  de: deLang,
  ar: arLang,
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.zh.data);
addLocaleData(AppLocale.it.data);
addLocaleData(AppLocale.es.data);
addLocaleData(AppLocale.fr.data);
addLocaleData(AppLocale.ja.data);
addLocaleData(AppLocale.he.data);
addLocaleData(AppLocale.de.data);
addLocaleData(AppLocale.ar.data);

export default AppLocale;
