import antdJa from 'antd/lib/locale-provider/ja_JP';
import appLocaleData from 'react-intl/locale-data/ja';
import jaMessages from '../locales/ja_JP.json';

const jaLang = {
  messages: {
    ...jaMessages,
  },
  antd: antdJa,
  locale: 'ja',
  data: appLocaleData,
};
export default jaLang;
