import { PAGE_LIMIT } from 'constants/Common';

export class ListConfig {
  static SORTABLE_COLUMNS = { stoneId: 'Stone No', shpNm: 'Shape', crt: 'Carat', colNm: 'Color', clrNm: 'Clarity' };
  static LIMIT = PAGE_LIMIT;
  // prettier-ignore
  static ALT_COLUMNS = ['lbNm', 'crt', 'colNm', 'clrNm', 'cutNm', 'polNm', 'symNm', 'back', 'rap', 'fluNm'];
  // prettier-ignore
  static BOLD_COLUMNS = ['stoneId', 'vStnId', 'colNm', 'shpNm', 'crt', 'amt', 'clrNm', 'shdNm', 'rap', 'back', 'ctPr', 'cutNm', 'polNm', 'symNm', 'brlncyNm', 'fluNm'];
  // prettier-ignore
  static FILTER_COLUMNS = ['colNm', 'fluNm', 'shpNm', 'lbNm', 'clrNm', 'locNm', 'shdNm', 'cutNm', 'polNm', 'symNm', 'brlncyNm', 'blkTblNm', 'blkSdNm', 'wSdNm', 'wTblNm', 'opCrwnNm', 'opPavNm', 'opTblNm', 'eClnNm', 'girdleStr', 'cultNm', 'hANm'];
  // prettier-ignore
  static CURRENCY_COLUMNS = ['amt', 'rap', 'ctPr'];
  // prettier-ignore
  static FLOAT_COLUMNS = ['depPer', 'ratio', 'crt', 'rapAvg', 'pAng', 'pHgt', 'cHgt', 'back', 'cAng', 'fnDis', 'height', 'width', 'length', 'grdlPer', 'strLn', 'tblPer', 'lwrHal', 'ctPr', 'amt'];
  // prettier-ignore
  static NOCHECK_COLUMNS = ['Details', 'Action', 'quote', 'expBack', 'hours', 'bidPricePerCarat', 'note', 'finalquote', 'offerDis', 'bidDiscount', 'bidAmount', 'fnCtPr', 'fnAmt'];
  // prettier-ignore
  static ROUND_COLUMNS = ['amt', 'rap'];
  // prettier-ignore
  static TOTAL_COLUMNS = ['ctPr', 'amt', 'rap', 'crt', 'back', 'fnCtpr', 'fnAmt'];
}
