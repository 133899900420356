import 'react-app-polyfill/ie9';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Root from 'containers/Root';

import { store } from 'appRedux/store';

import 'styles/main.less';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('root'),
);
