import antdZH from 'antd/es/locale-provider/zh_CN';
import appLocaleData from 'react-intl/locale-data/zh';
import zhMessages from '../locales/zh-Hans-CN.json';

const zhLang = {
  messages: {
    ...zhMessages,
  },
  antd: antdZH,
  locale: 'zh-Hans-CN',
  data: appLocaleData,
};
export default zhLang;
