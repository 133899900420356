import { Action } from 'services/Action';

import { hasKey, isArray, isString, logWarn, runInDevelopment } from 'util/utils';

import { LOCAL_STORAGE_VAR } from 'constants/Common';

const encoder = (input) => btoa(unescape(encodeURIComponent(input)));
const decoder = (input) => decodeURIComponent(escape(atob(input)));

export class Storage {
  static get prefix() {
    return `${LOCAL_STORAGE_VAR}-`;
  }
  static get action() {
    return new Action('storage');
  }

  /**
   * Store
   * @param {String} key
   * @param {*} value
   */
  static set(key, value, encode = true) {
    try {
      const _key = key.startsWith(this.prefix) ? key : `${this.prefix}${key}`;
      const _value = JSON.stringify(value);
      localStorage.setItem(_key, encode ? encoder(_value) : _value);
      Storage.action.emit({ [key]: value });
      return true;
    } catch (error) {
      logWarn({ key, value, encode, error });
      return false;
    }
  }

  /**
   * Retrieve
   * @param {String} key
   */
  static get(key, decode = true) {
    try {
      const _key = key.startsWith(this.prefix) ? key : `${this.prefix}${key}`;
      const _value = localStorage.getItem(_key);
      return JSON.parse(decode ? decoder(_value) : _value);
    } catch (error) {
      logWarn({ key, decode, error });
      return null;
    }
  }

  /**
   * Remove values by key
   * @param {String|Array} keys String or Array of string
   */
  static delete(keys) {
    keys = isArray(keys) ? keys : isString(keys) ? [keys] : [];
    keys.forEach((key) => {
      try {
        const _key = key.startsWith(this.prefix) ? key : `${this.prefix}${key}`;
        localStorage.removeItem(_key);
        Storage.action.emit({ [key]: undefined });
        return true;
      } catch (error) {
        logWarn({ key, error });
        return false;
      }
    });
  }

  /**
   * Remove values by key
   * @param {String|Array} exceptkeys String or Array of string
   */
  static deleteAll(exceptkeys = ['remember-me', 'remember-user']) {
    exceptkeys = isArray(exceptkeys) ? exceptkeys : isString(exceptkeys) ? [exceptkeys] : [];
    exceptkeys = exceptkeys.map((key) => (key.startsWith(this.prefix) ? key : `${this.prefix}${key}`));

    Object.keys(localStorage).forEach((key) => {
      try {
        const _key = key.startsWith(this.prefix) ? key : `${this.prefix}${key}`;
        if (exceptkeys.includes(_key)) return;

        localStorage.removeItem(_key);
        Storage.action.emit({ [key]: undefined });
        return true;
      } catch (error) {
        logWarn({ key, error });
        return false;
      }
    });
  }

  static listen(key, callback) {
    const unlisten = Storage.action.listen((event) => {
      if (!hasKey(event?.detail, key)) return;
      callback({ [key]: event?.detail?.[key] });
    });
    return unlisten;
  }
}

runInDevelopment(() => void (window.__Storage = Storage));
