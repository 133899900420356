import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { Redirect, Router, Route, Switch } from 'react-router-dom';

import Routes from 'containers/Routes';

import { ConnectedLoader } from 'components/ConnectedLoader';

import { Storage } from 'services/Storage';

import { history } from 'util/history';
import { loadable } from 'util/loadable';

import AppLocale from 'lngProvider';

import 'components/auth.less';

const ForgotPassword = loadable(() => import('components/Auth/ForgotPassword'));
const ResetPassword = loadable(() => import('components/Auth/ResetPassword'));
const BiddingProcedure = loadable(() => import('components/BiddingProcedure'));
const BookAppointment = loadable(() => import('components/BookAppointment'));
const Career = loadable(() => import('components/Career'));
const CareerDetail = loadable(() => import('components/Career/CareerDetail'));
const ContactUs = loadable(() => import('components/ContactUs'));
const DNA = loadable(() => import('components/DNA'));
const ViewImage = loadable(() => import('components/DNA/ViewImage'));
const DiamondDetail = loadable(() => import('components/DiamondDetail'));
const EmailVerify = loadable(() => import('components/EmailVerify'));
const FTC = loadable(() => import('components/FTC'));
const Feedback = loadable(() => import('components/Feedback'));
const GuestLogin = loadable(() => import('components/GuestLogin'));
const News = loadable(() => import('components/News'));
const PageNotFound = loadable(() => import('components/PageNotFound'));
const SignIn = loadable(() => import('components/SignIn'));
const SignUp = loadable(() => import('components/SignUp'));
const TermsOfUse = loadable(() => import('components/TermsOfUse'));
const ThankYouAfterSignup = loadable(() => import('components/ThankYou'));
const VerifyDevice = loadable(() => import('components/VerifyDevice'));
const VerifyOtp = loadable(() => import('components/VerifyOtp'));
const XRAY = loadable(() => import('components/XRAY'));

const SignOutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (Storage.get(`token`) ? <Redirect to={'/dashboard'} /> : <Component {...props} />)}
    />
  );
};
SignOutRoute.displayName = 'SignOutRoute';

function App(props) {
  const language = useSelector((state) => AppLocale[state?.commonData?.locale?.locale ?? 'en']);

  return (
    <Router history={history}>
      <ConnectedLoader />
      <IntlProvider locale={language.locale} messages={language.messages}>
        <Switch>
          <SignOutRoute path="/" component={SignIn} exact />
          <SignOutRoute path="/signin" component={SignIn} />
          <Route exact path="/verify-otp" component={VerifyOtp} />
          <SignOutRoute path="/signup" component={SignUp} />
          <SignOutRoute path="/device/signup" component={SignUp} />
          <SignOutRoute path="/guest-login" component={GuestLogin} />
          <SignOutRoute path="/forgot-password" component={ForgotPassword} />
          <SignOutRoute path="/reset-password/:token" component={ResetPassword} />
          <Route path="/thank-you" component={ThankYouAfterSignup} />
          <Route path="/verify-email" component={EmailVerify} />
          <Route path="/verify-device" component={VerifyDevice} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/device/contact-us" component={ContactUs} />
          <Route path="/xrays" component={XRAY} />
          <Route path="/DiamFTC" component={FTC} />
          <Route path="/diamond-details/" component={DiamondDetail} />
          <Route path="/dna" component={DNA} />
          <Route path="/viewImage" component={ViewImage} />
          <Route path="/career" component={Career} />
          <Route path="/career-detail" component={CareerDetail} />
          <Route exact path="/news" component={News} />
          <Route exact path="/terms-condition" component={TermsOfUse} />
          <Route exact path="/bidding-procedure" component={BiddingProcedure} />
          <Route exact path="/book-appointment" component={BookAppointment} />
          <Route exact path="/feedback" component={Feedback} />
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/not-found" component={PageNotFound} />
          <Routes {...props} />
        </Switch>
      </IntlProvider>
    </Router>
  );
}

export default React.memo(App);
