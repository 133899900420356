import React from 'react';

export function SVGLoader() {
  return (
    <div className="loading-indicator">
      <svg
        className="SVGLoader"
        height="50px"
        preserveAspectRatio="xMidYMid"
        style={{ margin: 'auto', background: 'none', display: 'block', shapeRendering: 'auto' }}
        viewBox="0 0 100 100"
        width="50px"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" stroke="none" transform="rotate(282.538 50 51)">
          <animateTransform
            attributeName="transform"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="rotate"
            values="0 50 51;360 50 51"
          />
        </path>
      </svg>
    </div>
  );
}
