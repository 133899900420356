import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useLocation, useRouteMatch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { LoaderService } from 'services/LoaderService';
import { Storage } from 'services/Storage';

import { isFunction, isNumber, isObject, queryStringToObject } from 'util/utils';

import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH, PAGE_LIMIT } from 'constants/Common';

export const useAutoRef = (value) => {
  const ref = React.useRef(value);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  React.useDebugValue(ref.current);
  return ref;
};

export const useBoolean = (initialValue = false) => {
  const [state, _setState] = React.useState(Boolean(initialValue));

  const setState = React.useMemo(() => {
    return {
      true: () => _setState(true),
      false: () => _setState(false),
      toggle: () => _setState((v) => !v),
    };
  }, []);

  React.useDebugValue(state);
  return [state, setState];
};

export const useCompositeState = (initialState) => {
  const [state, _setState] = React.useState(initialState);
  const initialStateRef = useAutoRef(initialState);

  const setState = React.useCallback((objectOrCallback, spread = true) => {
    const callback = isFunction(objectOrCallback) ? objectOrCallback : undefined;
    const object = isObject(objectOrCallback) ? objectOrCallback : {};

    _setState((state) => {
      const _object = callback ? callback(state) : object;
      return spread ? { ...state, ..._object } : { ..._object };
    });
  }, []);

  const resetState = React.useCallback(() => {
    _setState(initialStateRef.current);
  }, [initialStateRef]);

  React.useDebugValue(state);
  return [state, setState, resetState];
};

export const useCounter = (init = 0) => {
  const [count, set] = React.useState(init);

  const inc = React.useCallback((cb = () => {}) => set((n) => ((n = n > 0 ? ++n : 1), cb(n), n)), []);
  const dec = React.useCallback((cb = () => {}) => set((n) => ((n = n > 0 ? --n : 0), cb(n), n)), []);
  const reset = React.useCallback((cb = () => {}) => set((cb(0), 0)), []);

  React.useDebugValue(count);
  return [count, inc, dec, reset];
};

export const useLoading = (init = false, show = false) => {
  const [count, inc, dec] = useCounter(init ? 1 : 0);
  const countRef = React.useRef(count);
  React.useEffect(() => void (countRef.current = count), [count]);

  const start = React.useCallback(() => inc(() => show && LoaderService.startLoading()), [inc, show]);
  const stop = React.useCallback(() => dec(() => show && LoaderService.stopLoading()), [dec, show]);

  React.useEffect(() => {
    if (show) return () => {};
    const hasClass = document?.body?.classList?.contains?.('progress');
    void (count > 0
      ? !hasClass && document?.body?.classList?.add?.('progress')
      : hasClass && document?.body?.classList?.remove?.('progress'));
  }, [show, count]);

  React.useEffect(() => {
    return () => {
      if (show && isNumber(countRef.current) && countRef.current > 0) {
        LoaderService.adjustCount(-Math.abs(countRef.current));
      }
    };
  }, [show]);

  React.useDebugValue(count);
  return [Boolean(count), start, stop];
};

export const usePagination = (page = 1, limit = PAGE_LIMIT) => {
  const [state, _setState] = React.useState({ page, limit });
  const paramsRef = useAutoRef({ page, limit });

  const setPagination = React.useCallback((page, limit) => {
    _setState((state) => ({ page: page ?? state.page, limit: limit ?? state.limit }));
  }, []);

  const resetPagination = React.useCallback(() => {
    const { page, limit } = paramsRef.current ?? {};
    _setState({ page, limit });
  }, [paramsRef]);

  React.useDebugValue(state);
  return [state?.page, state?.limit, setPagination, resetPagination];
};

export const useList = (list = [], count = 0) => {
  const [state, _setState] = React.useState({ list, count });

  const setList = React.useCallback((list, count) => {
    _setState((state) => ({ list: list ?? state.list, count: count ?? state.count }));
  }, []);

  const resetList = React.useCallback(() => {
    _setState(() => ({ list: [], count: 0 }));
  }, []);

  React.useDebugValue(state);
  return [state?.list, state?.count, setList, resetList];
};

export const useStorage = (key, options) => {
  const keyRef = useAutoRef(key);
  const optionsRef = useAutoRef(options);

  const [value, _setValue] = React.useState(Storage.get(key, options?.decode) ?? options?.defaultValue);

  React.useEffect(() => {
    return Storage.listen(key, (store) => {
      const options = optionsRef.current;
      _setValue(store[key] ?? options?.defaultValue, options?.decode);
    });
  }, [key, optionsRef]);

  const setValue = React.useCallback(
    (value) => {
      Storage.set(keyRef.current, value, optionsRef.current?.decode);
    },
    [keyRef, optionsRef],
  );

  React.useDebugValue(value);
  return [value, setValue];
};

export const useForm = (initialValues, options) => {
  const [values, setValue] = React.useState(initialValues);
  const [errors, setError] = React.useState({});
  const [touched, setTouched] = React.useState(false);
  const optionsRef = useAutoRef(options);

  const handleChange = React.useCallback(
    (e) => {
      const { name, value } = e?.target ?? e;
      const { validate, transform } = optionsRef.current;

      const _value = transform?.[name]?.(value) ?? value;
      const _error = validate?.[name]?.() ?? undefined;
      setValue((_values) => ({ ..._values, [name]: _value }));
      setError((_errors) => ({ ..._errors, [name]: _error }));
      setTouched((_touched) => ({ ..._touched, [name]: true }));
    },
    [optionsRef],
  );

  const handleCheck = React.useCallback(
    (e) => {
      const { name, checked: value } = e?.target ?? e;
      const { validate, transform } = optionsRef.current;

      const _value = transform?.[name]?.(value) ?? value;
      const _error = validate?.[name]?.() ?? undefined;
      setValue((_values) => ({ ..._values, [name]: _value }));
      setError((_errors) => ({ ..._errors, [name]: _error }));
      setTouched((_touched) => ({ ..._touched, [name]: true }));
    },
    [optionsRef],
  );

  const handleBlur = React.useCallback((e) => {
    const { name } = e?.target ?? e;
    setTouched((_touched) => ({ ..._touched, [name]: true }));
  }, []);

  const output = React.useMemo(() => ({ errors, handleBlur, handleChange, handleCheck, touched, values }), [
    errors,
    handleBlur,
    handleChange,
    handleCheck,
    touched,
    values,
  ]);

  return output;
};

export const useIsMobile = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  const setSize = () => setWidth(window.innerWidth);

  React.useEffect(() => {
    setSize();
    window.addEventListener('resize', setSize);
    return () => window.removeEventListener('resize', setSize);
  }, []);

  const output = React.useMemo(() => {
    const isMobile = width <= MOBILE_MAX_WIDTH;
    const isTablet = width <= TABLET_MAX_WIDTH;
    return [isMobile, isTablet];
  }, [width]);

  React.useDebugValue(output);
  return output;
};

export const useQueryParams = () => {
  const location = useLocation();
  const output = React.useMemo(() => queryStringToObject(location.search), [location.search]);
  React.useDebugValue(output);
  return output;
};

export const usePathname = () => {
  const match = useRouteMatch();
  const pathname = React.useMemo(() => generatePath(match?.path)?.split('/')?.pop(), [match.path]);
  React.useDebugValue(pathname);
  return pathname;
};

export const useCurrentType = () => usePathname;

export const useSelectedRows = (currentType, options) => {
  options = { mapFields: [], ...options };
  const optionsRef = useAutoRef(options);

  const rowList = useSelector((state) => state?.diamondData?.selectedRows?.[currentType] ?? []);

  const rowMap = React.useMemo(() => {
    const { mapFields } = optionsRef.current;
    return Object.fromEntries(mapFields.map((field) => [field, rowList.map((row) => row?.[field])]));
  }, [optionsRef, rowList]);

  React.useDebugValue({ rowList, rowMap });
  return [rowList, rowMap];
};

export const useAction = (actionCreator) => {
  const dispatch = useDispatch();
  const action = React.useMemo(() => bindActionCreators(actionCreator, dispatch), [actionCreator, dispatch]);
  return action;
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => void clearTimeout(handler);
  }, [value, delay]);

  React.useDebugValue({ debouncedValue });
  return debouncedValue;
};
