// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';
export const SWITCH_STOCK = 'SWITCH-STOCK';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
// Sales person
export const SALES_DATA = 'SALES_DATA';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const OTP_VERIFICATION = 'OTP_VERIFICATION';
export const RESEND_OTP = 'RESEND_OTP';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const CURRENT_PAGE_DATA = 'CURRENT_PAGE_DATA';
export const USER_TOKEN_SET = 'user_token_set';
export const CATCH_MASTER_DATA = 'CATCH_MASTER_DATA';
export const LIST_DATA = 'list_data';
export const MASTER_DATA_LOGIN = 'MASTER_DATA_LOGIN';
export const KEYWORDS_DATA = 'KEYWORDS_DATA';
export const LOGIN_DROPDOWN = 'LOGIN_DROPDOWN';

//diamond deatails
export const DIAMOND_DATA = 'DIAMOND_DETAILS';
export const CART_COUNTER = 'CART_COUNTER';
export const DIAMONDID = 'DIAMONDID';
export const PARCEL_POINTER = 'PARCEL_POINTER';

export const DIAMOND_SELECTED_DATA = 'DIAMOND_SELECTED_DATA';
export const CART_ADDED = 'CART_ADDED';
export const ENQUIRY_INTRACT = 'ENQUIRY_INTRACT';
export const ENQUIRY_FETCH = 'ENQUIRY_FETCH';

// reminder-page actions
export const SET_FLAG = 'SET_FLAG';
export const SET_UPDATEFLAG = 'SET_UPDATEFLAG';
export const SET_INPUT = 'SET_INPUT';
export const SET_COUNT = 'SET_COUNT';
export const SET_SELECT = 'SET_SELECT';
export const SET_DELETE = 'SET_DELETE';
export const SET_UPDATE = 'SET_UPDATEReminder';
export const RESET_SELECT = 'RESET_SELECT';
export const RESET_COLUMN = 'RESET_COLUMN';
export const REMINDER_ACTION = 'REMINDER_ACTION';
export const QUERY_ID = 'QUERY_ID';
export const WATCHLIST_ACTION = 'WATCHLIST_ACTION';
export const IS_DISABLED = 'IS_DISABLED';
export const SET_EXPORT = 'SET_EXPORT';
export const ORDER_FLAG = 'ORDER_FLAG';
export const ORDER_DATA = 'ORDER_DATA';

/**
 * switing loader status
 */

export const SET_LOADER = 'SET_LOADER';
export const PAGE_PERMISSION = 'PAGE_PERMISSION';
export const NOTIFICATION_COUNTER = 'NOTIFICATION_COUNTER';

export const RESET_SEARCH = 'RESET_SEARCH';
export const MODIFY_SEARCH = 'MODIFY_SEARCH';
