const MESSAGES = {};

MESSAGES.DEFAULT = {
  'app.signin.welcome': 'Welcome to DiamNow',
  'app.Howdoyoulikeourwebsite?': 'How do you like our website?',
  'app.dashboard': 'Dashboard',
  'app.about': 'About',
  'app.AboutDiamNow': 'About DiamNow',
  'app.WhyDiamNow': 'Why DiamNow?',
  'app.Policy': 'Policy',
  'app.Help': 'Help',
  'app.AdvancePayment': 'Advance Payment',
  'app.advance-search': 'Advance Search',
  'app.signin.eventName': 'DiamNow Event name will be here',
  'app.signin.viewDetails': 'View more details...',
  'app.signin.eventDetails':
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  'app.signin.back': 'Back',
  'app.signin.loginWithSocial': 'Login with social media!',
  'app.signin.login': 'Welcome to DiamNow',
  'app.signin.username': 'Username',
  'app.signin.password': 'Password',
  'app.signin.rememberMe': 'Remember Me',
  'app.signin.forgotPassword': 'Forgot Password?',
  'app.signin.dontHaveLogin': 'Don’t have Login details?',
  'app.signin.register': 'Register',
  'app.signin.guestLogin': 'Guest Login',
  'app.signin.or': 'Or',
  'app.signup.back': 'Back',
  'app.signup.regWithSocial': 'Register with social media!',
  'app.signup.registration': 'Registration',
  'app.Registration': 'Registration',
  'app.signup.firstName': 'First Name',
  'app.signup.lastName': 'Last Name',
  'app.signup.prinfotitle': 'Personal Information',
  'app.signup.password': 'Password',
  'app.signup.bussinessInfo': 'Business Information',
  'app.signup.bussinessType': 'Business Type',
  'app.signin.otpVerification': 'Verify your OTP',
  'app.signin.otp': 'OTP',
  'app.signup.address': 'Address Line1',
  'app.signup.address2': 'Address Line2',
  'app.signup.emailaddress': 'Email',
  'app.signup.username': 'Username',
  'app.signup.cPassword': 'Confirm Password',
  'app.signup.birthDate': 'Birth Date',
  'app.signup.anniversaryDate': 'Anniversary Date',
  'app.signup.mobileNo': 'Mobile No.',
  'app.signup.telephone': 'Telephone No.',
  'app.signup.companyName': 'Company Name',
  'app.signup.designation': 'Designation',
  'app.signup.country': 'Country',
  'app.signup.state': 'State',
  'app.signup.city': 'City',
  'app.signup.pincode': 'Postal Code',
  'app.signup.fax': 'Fax',
  'app.signup.registerButton': 'Register',
  'app.signup.howDoYouKnowUs': 'How do you know us?',
  'app.signup.temsDoc': 'Terms and Documentations',
  'app.signup.photoIdentity': 'Photo Identity Proof',
  'app.signup.imageDrag': 'Drag an image here or browse for an image to upload',
  'app.signup.imageUpload': 'Upload Image',
  'app.signup.docsInfo': 'Passport, Driving Licence (scan and upload only .jpg, .jpeg, .png and .pdf files)',
  'app.signup.bussinessIdProof': 'Business Identity Proof',
  'app.signup.bussinessDoc': 'Business Proof (scan and upload only .jpg, .jpeg, .png and .pdf files)',
  'app.signup.doyouAgree': 'Do you agree?',
  'app.signup.kycFrom': 'KYC Form',
  'app.signup.kycUndertaking': 'KYC Undertaking',
  'app.signup.iAgree': 'I have read and agree to the',
  'app.signup.temsCondition': 'Terms & Conditions',
  'app.signup.Submit': 'Submit',
  'app.myDiamNow': 'DiamNow',
  'app.other-details': 'Other Details',
  'app.my-account.PersonalDetails': 'Personal Details',
  'app.my-account.UploadImage': 'Upload Image',
  'app.my-account.RemoveProfile': 'Remove Profile',
  'app.my-account.Name': 'Name',
  'app.my-account.email': 'Email',
  'app.my-account.mobile': 'Mobile',
  'app.my-account.AnniversaryDate': 'Anniversary Date',
  'app.my-account.SavePersonalDetail': 'Save Personal Detail',
  'app.my-account': 'Personal Details',
  'app.company-details': 'Company Details',
  'app.manufacturing-details': 'Manufacturing Facility Details',
  'app.terms-and-documentation': 'Terms and Documentation',
  'app.settings': 'Settings',
  'app.change-password': 'Change Password',
  'app.CompanyDetails': 'Company Details',
  'app.CompanyName': 'Company Name',
  'app.BusinessType': 'Business Type',
  'app.Designation': 'Designation',
  'app.Addressine1': 'Address Line 1',
  'app.Addressine2': 'Address Line 2',
  'app.SaveCompanyDetail': 'Save Company Detail',
  'app.UpdateSequence': 'Update',
  'app.CurrentPassword': 'Current Password',
  'app.NewPassword': 'New Password',
  'app.RepeatPassword': 'Repeat Password',
  'app.ConfirmPassword': 'Confirm Password',
  'app.Save': 'Save',
  'app.update': 'Update',
  'app.Guest.loginWithSocial': 'Login with social media!',
  'app.Guest.login': 'Login As Guest',
  'app.Guest.Name': 'Name',
  'app.Guest.comapnyName': 'Company Name',
  'app.Guest.Contact': 'Contact No',
  'app.Guest.email': 'Email',
  'app.Guest.loginButton': 'Login',
  'app.Guest.cancel': 'Cancel',
  'app.customerCare.BookAppointment': 'Book Appointment',
  'app.customerCare.eventCalendar': 'Event Calendar',
  'app.customerCare.conciergeServices': 'Concierge Services',
  'app.customerCare.faqs': 'FAQs',
  'app.customerCare.contact': 'Contact',
  'app.customer-testimonial': 'Customers Testimonials',
  'app.DiamNow-news': 'DiamNow News',
  'app.Educational': 'Educational',
  'app.CustomerTestimonials': 'Customers Testimonials',
  'app.DiamNowNews': 'DiamNow News',
  'app.CSRatDiamNow': 'CSR at DiamNow',
  'app.DateTitle': 'Date :',
  'app.laterToday': 'Later Today',
  'app.tomorrow': 'Tomorrow',
  'app.nextWeek': 'Next Week',
  'app.pickdatetime': 'Pick Date & Time',
  'app.StnIDRprtNo': 'Stone Id/ Report No.',
  'app.StnID': 'Stone ID',
  'app.StnAmt': 'Stone Amount',
  'app.delAll': 'Are you sure you want to delete all stone(s)?',
  'app.delThese': 'Are you sure you want to delete these stone(s)?',
  'app.basicFilters': 'Basic Filters',
  'app.advanceFilters': 'Advanced Filters',
  'app.clearAllFilter': 'Clear All',
  'app.DiamNowGrading': 'DiamNow Grading',
  'app.UsefulLinks': 'Useful Links',
  'app.SiteMap': 'Sitemap',
  'app.news-media': 'News',
  'app.corporate-social-responsibility': 'CSR / Activities',
  'app.Feedback': 'Feedback',
  'app.Suggestion': 'Suggestions',
  'app.SuggestedCode': 'Suggested Code',
  'app.SubmityourFeedback': 'Submit your Feedback',
  'app.copyrightText': 'Copyright ©  ',
  'app.Allrightsreserved': 'All rights reserved.',
  'app.CustomerService': 'Customer Service',
  'app.Subscribe': 'Subscribe',
  'app.Gettheappnow!': 'Get the now!',
  'app.TogetourNewsletter': 'To get our Newsletter',
  'app.Enteryouremail': 'enter your email',
  'app.ContactUs': 'Contact Us',
  'app.EmailAddress': 'Email Address',
  'app.Message': 'Message',
  'app.SubmitMessage': 'Send Suggestion',
  'app.Headoffice': 'Head office',
  'app.DiamNowAffiliates': 'DiamNow Group Affiliates',
  'app.GetinTouch': 'Get in Touch',
  'app.Quickandeasysearch': 'Quick and easy search',
  'app.Reviewatyourconvenience': 'Review at your convenience',
  'app.Buydiamondsinjust3clicks': 'Buy diamonds in just 3 clicks',
  'app.Collectionofover25 000diamonds': 'Collection of over 25 000 diamonds',
  'app.OurPhilosophy': 'Our Philosophy',
  'app.Careers': 'Careers',
  'app.achievement': 'Achievement',
  'app.OurStory': 'Our Story',
  'app.EntityBrands': 'Entity & Brands',
  'app.Services': 'Services',
  'app.CSR/Activities': 'CSR / Activities',
  'app.NewsMedia': 'News & Media',
  'app.Presence': 'Presence',
  'app.Close': 'Close',
  'app.CancelAppointment': 'Cancel Appointment',
  'app.Demand': 'Demand',
  'app.Purpose': 'Purpose',
  'app.SalesPerson': 'Sales Person',
  'app.Division': 'Division',
  'app.Cabin': 'Cabin',
  'app.AppointmentFor': 'Appointment For',
  'app.Yes': 'Yes',
  'app.No': 'No',
  'app.4cs': "4C's",
  'app.cut-and-polished-diamonds': 'Cut and Polished Diamonds',
  'app.wholesale-diamonds': 'Wholesale Diamonds',
  'app.certified-diamonds': 'Certified Diamonds',
  'app.white-diamonds': 'White Diamonds',
  'app.fancy-diamonds': 'Fancy Diamonds',
  'app.loose-diamonds': 'Loose Diamonds',
  'app.Best_DiamNow_Diamonds': 'Beste DiamNow Diamanten',
  'app.DownloadInventory': 'Download Inventory',
  'app.DownloadCSV': 'Download CSV',
  'app.Notification': 'Notification',
  'app.your-price': 'Your Price',
  'app.CT': 'CT',
  'app.TTL': 'TTL',
  'app.List-Price': 'List Price',
  'app.Title': 'Title',
  'app.Expirydate': 'Expiry Date',
  'app.Calculator': 'Calculator',
  'app.TrackShipment': 'TrackShipment',
  'app.Cart': 'Add to Cart',
  'app.deleteMessege': 'Are you sure you want to delete?',
  'app.diamonddetail': 'Diamond Details',
  'app.diamondhistory': 'My Diamond Story',
  'app.image': 'Image',
  'app.video': 'Video',
  'app.downloadImage': 'Download Image',
  'app.downloadVideo': 'Download Video',
  'app.downloadCertificate': 'Download Certificate',
  'app.diamonddetails.basicinfo': 'Basic Info',
  'app.diamonddetails.Inclusion': 'Inclusion Details',
  'app.diamonddetails.Inclusion.blacktable': 'Table Black',
  'app.diamonddetails.Inclusion.tableopen': 'Open Table',
  'app.diamonddetails.Inclusion.luster': 'Luster',
  'app.diamonddetails.Inclusion.blackcrown': 'Black Crown',
  'app.diamonddetails.Inclusion.milky': 'Milky',
  'app.diamonddetails.Inclusion.pavillionopen': 'Open Pavilion',
  'app.diamonddetails.Inclusion.whitetable': 'Table White',
  'app.diamonddetails.Inclusion.crownopen': 'Open Crown',
  'app.diamonddetails.Inclusion.eyeclean': 'Eye Clean',
  'app.diamonddetails.Inclusion.whitecrown': 'White Crown',
  'app.diamonddetails.Inclusion.heartandaerrow': 'H&A',
  'app.diamonddetails.Additionalinfo': 'Additional Info',
  'app.diamonddetails.Additionalinfo.certificate': 'Certificate',
  'app.measurement': 'Measurement',
  'app.diamonddetails.Additionalinfo.laserinscription': 'Laser Inscription',
  'app.diamonddetails.Additionalinfo.coloeshade': 'Color Shade',
  'app.diamonddetails.Additionalinfo.StarLength': 'Star Length',
  'app.diamonddetails.Additionalinfo.Tableper': 'Table%',
  'app.diamonddetails.Additionalinfo.Location': 'Location',
  'app.diamonddetails.Additionalinfo.ratio': 'Ratio',
  'app.diamonddetails.Additionalinfo.DiameterMin': 'Diameter Min',
  'app.diamonddetails.Additionalinfo.Comment': 'Lab Comment',
  'app.diamonddetails.Additionalinfo.TableDepth': 'Total Depth%',
  'app.diamonddetails.Additionalinfo.Girdle': 'Girdle%',
  'app.diamonddetails.Additionalinfo.PavalionAngle': 'Pavilion Angle °',
  'app.diamonddetails.Additionalinfo.KeyToSymbol': 'Key to symbol',
  'app.diamonddetails.Additionalinfo.RoughOrigin': 'Rough Origin',
  'app.diamonddetails.Additionalinfo.Culet': 'Culet %',
  'app.diamonddetails.Additionalinfo.CrownAngle': 'Crown Angle °',
  'app.diamonddetails.Additionalinfo.LowerHalf': 'Lower Half',
  'app.diamonddetails.Additionalinfo.DiameterMax': 'Diameter Max',
  'app.compare.action': 'Actions',
  'app.compare': 'Compare',
  'app.diamondImage': 'Diamond Image',
  'app.addWatchlist': 'Add To Track',
  'app.add-track': 'Add To Track',
  'app.compare.packetNo': 'Packet No',
  'app.compare.shape': 'Shape',
  'app.compare.carat': 'Carat',
  'app.compare.clarity': 'Clarity',
  'app.compare.color': 'Color',
  'app.compare.flo': 'Fluorescence',
  'app.compare.rapper': 'Rap %',
  'app.compare.raprate': 'Rap Rate',
  'app.compare.pricepercarat': 'Pr/Ct',
  'app.compare.amount': 'Amount',
  'app.comppare.totaldepthper': 'Total Depth %',
  'app.compare.tableper': 'Table %',
  'app.compare.LWratio': 'Ratio',
  'app.compare.cut': 'Cut',
  'app.compare.polish': 'Polish',
  'app.compare.sym': 'Sym',
  'app.compare.flouresence': 'Fluorescence',
  'app.compare.FC': 'FC',
  'app.compare.lab': 'Lab',
  'app.compare.roughOrigin': 'Rough Origin',
  'app.compare.location': 'Location',
  'app.covid': 'Evaluate COVID‑19 symptoms and understand next steps',
  'app.carat': 'Carat',
  'app.discover': 'Discover',
  'app.Origin': 'Origin',
  'app.Allotting': 'Allotting',
  'app.Assessing': 'Assessing',
  'app.PlanningTheDiamond': 'Planning The Diamond',
  'app.LaserCuttingTheRough': 'Laser Cutting The Rough',
  'app.DiamondPolishing': 'Diamond Polishing',
  'app.DiamondQuality': 'Diamond Quality',
  'app.DiamondGrading': 'Diamond Grading',
  'app.DiamondJourney': 'My Diamond Story',
  'app.EnquiryData': 'My Enquiry',
  'app.Discover': 'Discover',
  'app.Exclusive': 'Exclusive',
  'app.7Days': '7 Days',
  'app.LiveBid': 'Live Bid',
  'app.Upcomming': 'Upcoming Diamonds',
  'app.newdiamond': 'New Diamond',
  'app.BestOfDiamNow': 'Best of DiamNow',
  'app.Round': 'Round',
  'app.Princess': 'Princess',
  'app.Pear': 'Pear',
  'app.Heart': 'Heart',
  'app.Oval': 'Oval',
  'app.Emerald': 'Emerald',
  'app.Radiant': 'Radiant',
  'app.Cushion': 'Cushion',
  'app.Marquise': 'Marquise',
  'app.ParcelList': 'Parcel Price List',
  'app.ParcelSearch': 'Parcel Serach',
  'app.matchpair-settings': 'Match Pair Settings',
  'app.appointment-history': 'Appointment History',
  'app.myorder': 'My Order',
  'app.shipment': 'My Shipment',
  'app.Remarks': 'Remarks',
  'app.VerifyCertificate': 'Verify Certificate',
  'app.ShareXRay': 'Share X-Ray',
  'app.sendXray': 'Send X-Ray',
  'app.AddToCart': 'Add To Cart',
  'app.Pieces': 'Pieces:',
  'app.Carats': 'Carat:',
  'app.Cts': 'Cts:',
  'app.AvgDisc': 'Avg Disc:',
  'app.TotalCt/Pr': 'Avg Price/Ct:',
  'app.Amount': 'Amount',
  'app.AmountHead': 'Amount:',
  'app.IAmLucky': 'Recommended',
  'app.Recomm-ended': 'Recomm-ended',
  'app.Logout': 'Log Out',
  'app.BookanAppointment': 'Book an Appointment',
  'app.CustomerCare': 'Customer Care',
  'app.Startsearching': 'Start searching diamonds ...',
  'app.Cancel': 'Cancel',
  'app.PlaceComment': 'Add Note',
  'app.PlaceEnquiry': 'Place Enquiry',
  'app.ShippmentRequest': 'Shipment Request',
  'app.Notes': 'Notes:',
  'app.Shape': 'Shape',
  'app.AddCarat': 'Add Carat',
  'app.Carat': 'Carat',
  'app.Color': 'Color',
  'app.COLOR GROUP': 'Color Group',
  'app.CLARITY GROUP': 'Clarity Group',
  'app.Clarity': 'Clarity',
  'app.Discount': 'Discount',
  'app.Pr/Cr': 'Pr/Cr',
  'app.Delete': 'Delete',
  'app.Fancy': 'Trade',
  'app.Parcel': 'Parcel',
  'app.Newgoods': 'New goods',
  'app.Upcoming': 'Upcoming',
  'app.AddToExisting': 'Add To Existing Tracklist',
  'app.AddNewWatchlist': 'Add New Tracklist',
  'app.name': 'Name',
  'app.BoothNo': 'Booth No',
  'app.Date': 'Date :',
  'app.GIAReportCheck': 'GIA Report Check',
  'app.Certified_Diamonds': 'Certified Diamonds',
  'app.Cut_and_Polished_Diamonds': 'Cut and Polished Diamonds',
  'app.Fancy_Diamonds': 'Fancy Diamonds',
  'app.Loose_Diamonds': 'Loose Diamonds',
  'app.Wholesale_Diamonds': 'Wholesale Diamonds',
  'app.signin.clarity': 'Clarity',
  'app.searchResult': 'Search Result',
  'app.add-watchList': 'Add To Tracklist',
  'app.my-Watchlist': 'My Tracklist',
  'app.my-enquiry': 'My Enquiry',
  'app.add-to-cart': 'Add To Cart',
  'app.my-comment': 'My Notes',
  'app.showSelect': 'Show Selected',
  'app.print': 'Print',
  'app.downloadExcel': 'Download Excel',
  'app.emailExcel': 'Email Excel',
  'app.comment': 'Note',
  'app.reminder': 'Reminder',
  'app.gridView': 'Grid View',
  'app.ListView': 'List View',
  'app.EnquiryButton': 'Enquiry',
  'app.PlaceOrder': 'Place Order',
  'app.Columns': 'Columns',
  'app.ItemPerPage': 'item per page',
  'app.of': 'of',
  'app.item': 'items',
  'app.Available': 'Available',
  'app.BusinessProcess': 'Business Process',
  'app.New': 'New',
  'app.InLab': 'In Lab',
  'app.All': 'All',
  'app.export': 'Export',
  'app.modify': 'Modify Search',
  'app.search-diamond': 'Search Diamonds',
  'app.shapes': 'Shapes',
  'app.allshapes': 'All Shapes',
  'app.all-data': 'All',
  'app.carat-range': 'Carat Range',
  'app.reset-carat': 'Reset Carat',
  'app.color': 'Color',
  'app.intensity': 'Intensity',
  'app.overtone': 'Overtone',
  'app.clarity': 'Clarity',
  'app.flor': 'Fluorescence',
  'app.color-shade': 'Color Shade',
  'app.lab': 'Lab',
  'app.cut': 'Cut',
  'app.location': 'Location',
  'app.polish': 'Polish',
  'app.symm': 'Symmetry',
  'app.new-arrival': 'Arrivals',
  'app.ratio': 'Ratio',
  'app.show-id': 'Show ID',
  'app.add-demand': 'Add Demand',
  'app.financial-data': 'Financial Data',
  'app.add-request': 'Add Request',
  'app.create-excel': 'Create Excel',
  'app.uploadExcel': 'Import Excel',
  'app.search-btn-footer': 'Search',
  'app.reset-filter': 'Reset Filter',
  'app.save-search': 'Save & Search',
  'app.update-search': 'Update & Search',
  'app.match-pair': 'Match Pair',
  'app.pair-no': 'Pair no',
  'app.price-comments': 'Price and Amount',
  'app.param': 'Parameters',
  'app.inclusion': 'Inclusion',
  'app.rap-dis': 'Rap Dis',
  'app.total-depth': 'Depth%',
  'app.pav': 'Pavilion',
  'app.pavDepth': 'Pv. Depth',
  'app.dia-max': 'Diameter Maximum',
  'app.price-carat': 'Price/Carat',
  'app.table%': 'Table%',
  'app.crown': 'Crown',
  'app.crown-height%': 'Crown Height%',
  'app.girdle': 'Girdle',
  'app.girdle-con': 'Girdle Condition',
  'app.culet': 'Culet',
  'app.culet-con': 'Culet Condition',
  'app.dia-min': 'Diameter Minimum',
  'app.black-table': 'Black',
  'app.black-crown': 'Black Crown',
  'app.white': 'White',
  'app.white-crown': 'White Crown',
  'app.white-table': 'White',
  'app.milky': 'Milky',
  'app.open-table': 'Open',
  'app.open-crown': 'Open Crown',
  'app.open-pav': 'Open Pavilion',
  'app.eye-clean': 'Eye Clean',
  'app.rough-origin': 'Rough Origin',
  'app.heart-arrow': 'Heart & Arrow',
  'app.from': 'From',
  'app.to': 'To',
  'app.my-demand': 'Buy Request',
  'app.key-to-symbol': 'Key to symbol',
  'app.quick-search': 'Quick Search',
  'app.quick-search-shapes': 'Select Diamond Shapes',
  'app.add-more-search': 'Add More Search',
  'app.View': 'View',
  'app.TotalFoundStones': 'Total Found Stones',
  'app.Doesnotcontains': 'Does not contains',
  'app.Contains': 'Contains',
  'app.upcoming-appoint': 'Upcoming',
  'app.archived-appoint': 'Archived',
  'app.cancelled-appoint': 'Cancelled',
  'app.showAll': 'Show All',
  'app.selectedDiamonds': 'Selected Diamonds',
  'app.deleteButton': 'Delete',
  'app.updateButton': 'Update',
  'app.updatereminder': 'Update Reminder',
  'app.deleteConfirm': 'Delete Confirmation',
  'app.addreminder': 'Add Reminder',
  'app.savedsearchtitle': 'Saved Search',
  'app.recentsearchtitle': 'Recent Search',
  'app.inshipmenttitle': 'In Shipment',
  'app.bookAppointmentDashboard': 'Book Appointment',
  'app.newArrivalsDashboard': 'New Goods',
  'app.watchlistDashboard': 'Tracklist',
  'app.matchpairDashboard': 'Match Pair',
  'app.offerDashboard': 'My Quote',
  'app.featuredStoneDashboard': 'Featured Stone',
  'app.viewallDashboard': 'View All',
  'app.featuredPairDashboard': 'Featured Pair',
  'app.purchaseSnapshotDashboard': 'Purchase Snapshot',
  'app.portfolioDashboard': 'Portfolio',
  'app.customerCareDashboard': 'Customer Care',
  'app.eventCalendarDashboard': 'Event Calendar',
  'app.conciergeServicesDashboard': 'Concierge Services',
  'app.faqDashboard': 'FAQs',
  'app.contactDashboard': 'Contact',
  'app.cabinforappointment': 'Cabin for appointment',
  'app.DivisionName': 'Division Name',
  'app.AppointmentDate&Time': 'Appointment Date & Time',
  'app.DemandIf': 'Demand (If Any)',
  'app.CompanyAddress': 'Company Address',
  'app.updateComment': 'Update Note',
  'app.stoneId': 'Seller Stone No',
  'app.action': 'Action',
  'app.appointment': 'Appointment',
  'app.calendar': 'Calendar',
  'app.concierge-services': 'Concierge Services',
  'app.FAQs': 'FAQs',
  'app.contact': 'Contact',
  'app.CancleAppoinmentMsg': 'Are you sure you want to cancel appointment?',
  'app.setting': 'Setting',
  'app.filter': 'Filter',
  'app.Status': 'Status',
  'app.watch-list': 'Tracklist',
  'app.SearchHistory': 'Search History',
  'app.Purchase': 'Purchase',
  'app.Order': 'Order',
  'app.bestDiamNowDiamonds': 'Best DiamNow',
  'app.stoneOfTheDay': 'Stone Of The Day',
  'app.FavoriteSearch': 'Favorite Search',
  'app.UserAccount': 'User Account',
  'app.DiamNowExclusive': 'DiamNow Exclusive',
  'app.newArrival': "New Arrival 'Just Bid It'",
  'app.Site-Map': 'Site Map',
  'app.address-book': 'Address Book',
  'app.Leading-edge': 'Leading-edge',
  'app.Diamond-Buying-App': 'Diamond Buying App.',
  'app.Nowavailableinofflinemode': 'Now available in offline mode.',
  'app.Our': 'Our',
  'app.Mission': 'Mission &',
  'app.Vision': 'Vision',
  'app.GridColumns': 'Grid Columns',
  'app.view-search': 'View Search',
  'app.AboutUs': 'About Us',
  'app.Diamond': 'Diamond',
  'app.Shipping': 'Shipping',
  'app.PaymentTerms': 'Payment Terms',
  'app.signup.npsUpdateRequired': 'Do you wish to receive promotional offers, newsletters and stock updates?',
  'app.isDefault': 'Is Default',
  'app.AddNew': 'Add New',
  'app.AddressType': 'Address Type',
  'app.Street': 'Street',
  'app.feedReset': 'Reset',
  'app.feedSubmit': 'Submit Your Feedback',
  'app.resetpassword': 'Reset Password',
  'app.newpassword': 'New Password',
  'app.confirmpassword': 'Confirm Password',
  'app.resetpasswordbutton': 'Reset Password',
  'app.selectdate': 'Select Date',
  'app.ids': 'StoneID/ Report Number',
  'app.download-sample': 'Download Sample',
  'app.gridStoneIdTooltip': 'Click to view X-Ray',
  'app.noSaveSearch': 'No saved search found. Please save search for quick search.',
  'app.noPurchaseFound': 'No purchase found.',
  'app.noRecentSearch': 'No recent search found.',
  'app.downloadInvoice': 'Download Invoice',
  'app.invoice': 'Invoice',
  'app.buyerName': 'Buyer Name',
  'app.DiamNowachievement': 'DiamNow Achievements',
  'app.notifications': 'Notifications',
  'app.markallread': 'Mark all as read',
  'app.delAddress': 'Are you sure you want to delete this address?',
  'app.SendEmail': 'Send Email',
  'app.Subject': 'Subject',
  'app.MailTo': 'Mail To',
  'app.MailCC': 'Mail CC',
  'app.MyPurchase': 'My Purchase',
  'app.ColorDescription': 'Color Description',
  'app.reschedule': 'Reschedule Appointment',
  'app.Doyoutrustthisdevice?': 'Signin using this browser for',
  'app.notgetanyOTP': "Didn't get any OTP",
  'app.ResendOTP': 'Resend OTP',
  'app.always': 'Always',
  'app.justonce': 'Just Once',
  'app.zipButton': 'Zip Dwonload',
  'app.downloadZip': 'Download Zip',
  'app.printExcel': 'Print Excel',
  'app.back-to-login': 'Back to Login',
  'app.copy-link': 'Copy Link',
  'app.priceListInfo': 'We will be using the price list of 06/03/2020.',
  'app.Manage-My-Diamonds': 'Diamonds',
  'app.TradScreen': 'Trade',
  'app.Upload-Diamonds': 'Upload',
  'app.People': 'People',
  'app.Report': 'Report',
  'app.Summary': 'Summary',
  'app.my-cart': 'My Cart',
  'app.my-watchlist': 'My Watchlist',
  'app.my-reminder': 'My Reminder',
  'app.office-list': 'My Office List',
  'app.confirm-list': 'My Requested list',
  'app.quote-list': 'My Quote List',
  'app.saved-search': 'My Saved Search',
  'app.my-demand-tab': 'My Demand',
  'app.myAccount': 'My Account',
  'app.Saved-Searches': 'Searches',
  'app.Tracked-Diamonds': 'Track',
  'app.totalDiamondListed': 'Total Diamond Listed',
  'app.UnreadMessages': 'Unread Messages',
  'app.BuyRequest': 'Buy Requests',
  'app.moreSearch': 'More Search',
  'app.user-details': 'User Details',
  'app.advance-measure': 'Advance Measurement',
  'app.change-lang': 'Change Language',
  'app.make': 'Make',
  'app.makeDetails': 'Make Details',
  'app.sellers': 'Seller(s)',
  'app.company-codes': 'Company Code(s) / Company Name(s) / ID(s)',
  'app.seller-numbers': 'Seller Stone Number(s)',
  'app.length': 'Length(mm)',
  'app.width': 'Width(mm)',
  'app.depth': 'Height(mm)',
  'app.height%': 'Height%',
  'app.angle': 'Angle',
  'app.switchToGlobalDash': 'Switch To Global Dashboard',
  'app.switchToPersonalDash': 'Switch To Personal Dashboard',
  'app.Welcome': 'Welcome',
  'app.welcomeBack': 'Welcome',
  'app.RecentActivity': 'Recent Activity',
  'app.PortfolioHead': 'Your Inventory Pricing V/S Global Pricing',
  'app.TrackHead': 'Tracked Diamonds Pricing',
  'app.Insights': 'Insights',
  'app.searchAppear': 'Search Appearances',
  'app.proView': 'Profiles Viewed',
  'app.diaView': 'Diamonds Viewed',
  'app.setBuyReq': "Can't find what you are looking for? Add a Buy Request",
  'app.Size': 'Size',
  'app.addbuy': 'Add Buy Request',
  'app.financ': 'Financial Data',
  'app.worClo': 'World Clock',
  'app.diamondListed': 'Diamonds Listed',
  'app.saleEnq': 'Sales Enquiry',
  'app.buyEnq': 'Buy Enquiry',
  'app.trackDia': 'Tracked Diamonds',
  'app.updateNote': 'Update Note',
  'app.addNote': 'Add Note',
  'app.addToTrack': 'Add To Track',
  'app.removeTrack': 'Remove Track',
  'app.remTrack': 'Add Track',
  'app.stockId': 'DiamNow Stone No',
  'app.new': 'New',
  'app.open': 'Open',
  'app.won': 'Won',
  'app.closed': 'Closed',
  'app.noData': 'No data found.',
  'app.grpBy': 'Group By',
  'app.buyer': 'Buyer',
  'app.date': 'Date',
  'app.manageDia': 'Manage Diamonds',
  'app.avail': 'Available',
  'app.inMemo': 'On Memo',
  'app.unAvail': 'Unavailable',
  'app.filters': 'Filter',
  'app.apply': 'Apply',
  'app.StockNo': 'Stock No',
  'app.cerImg': 'Cert Image',
  'app.hna': 'H&A',
  'app.certNo': 'Certificate No',
  'app.blocked_only': 'Blocked Only',
  'app.basicDetail': 'Basic Details',
  'app.priceDetail': 'Price Details',
  'app.paraDetail': 'Parameter Details',
  'app.depPer': 'Depth%',
  'app.height': 'Height',
  'app.morePara': 'More Parameter Details',
  'app.crHt': 'Crown Height',
  'app.crAn': 'Crown Angle',
  'app.pavHt': 'Pavilion Height',
  'app.pavAn': 'Pavilion Angle',
  'app.inclusions': 'Inclusions',
  'app.keyToS': 'Key To Symbol',
  'app.labCom': 'Laboratory Comments',
  'app.memCom': 'Member Comments',
  'app.supCom': 'Seller Comments',
  'app.lastLog': 'Last Logged In on',
  'app.more': 'More',
  'app.less': 'Less',
  'app.more-filter': 'More Filter',
  'app.min-price': 'Min Price',
  'app.max-price': 'Max Price',
  'app.already-have-acc': 'Already have an account?',
  'app.sign-In': 'Login',
  'app.fancy': 'Fancy',
  'app.comingsoon': 'Coming Soon',
  'app.trend': 'Trending Articles',
  'app.mFluc': 'Inventory snapshot',
  'app.Announcements': 'Announcements',
  'app.User': 'User',
  'app.SeeAll': 'See All',
  'app.Advertisement': 'Advertisement',
  'app.upload-logo': 'Upload Logo',
  'app.remove-logo': 'Remove Logo',
  'app.comp-code': 'Company Code',
  'app.user-type': 'User Type',
  'app.add-user': 'Add User',
  'app.update-user': 'Update User',
  'app.Select': 'Select',
  'app.select-saved-search': 'Select Saved Search',
  'app.enquiry': 'Enquiry',
  'app.uploadTitle': 'Upload Diamonds / Images / Certificates',
  'app.history': 'History',
  'app.FTP': 'FTP',
  'app.certi': 'Certificates',
  'app.images': 'Images',
  'app.diamonds': 'Diamonds',
  'app.SelectFile': 'Select File',
  'app.browse': 'Drop file here or Browse',
  'app.addnup': 'Add & Update',
  'app.dis': 'New lots will be added and existing lots will be updated. Lots not included will remain untouched.',
  'app.ReplaceAll': 'Replace All',
  'app.msg': 'Lots not included in the upload will be deleted.',
  'app.chk': 'Send an upload report to my email',
  'app.important': 'important',
  'app.replaced': 'Replaced Stock',
  'app.LotReceived': 'Lot Received',
  'app.VaildLots': 'Vaild Diamonds',
  'app.InvalidLots': 'Invalid Diamonds',
  'app.Duration': 'Duration',
  'app.Added': 'Added',
  'app.Updated': 'Updated',
  'app.Deleted': 'Deleted',
  'app.desc1': 'All type of treatments must be specified in the Treatment field.',
  'app.link1': 'Download CSV template',
  'app.or': 'or',
  'app.clickhere': 'Click here',
  'app.desc2': 'for detailed instructions and allowed values.',
  'app.desc3': 'After uploading your diamonds, click on Images and Certificates tab to upload images and certificates.',
  'app.Descp':
    'Please note that DiamNow listings are only active for 7 days. Refresh the listings periodically to ensure your stock is displayed on DiamNow.',
  'app.Descw': 'DiamNow strictly prohibits the listing of',
  'app.desca': 'Lab-grown diamonds',
  'app.descb': 'White diamonds with a green thigs/hue/shade and',
  'app.descc': 'Any diamonds originating from Zimbabwe',
  'app.longDesc':
    'Please note that any/all treatments on diamonds must be clearly disclosed in the treatments fields and misrepresentation is not allowed (laser drilled, color treated. HPHT etc.).',
  'app.didnt-match': "Sorry, we didn't find a match.",
  'app.choose-option':
    'Please choose an option from here. You can add it as demand to get notified when goods are available or view similar diamonds.',
  'app.get-noti': 'Get Notification',
  'app.member-directory': 'Member Directory',
  'app.member-name': 'Member Name',
  'app.comp-type': 'Company Type',
  'app.website': 'Website',
  'app.address': 'Address',
  'app.INFO': 'Information',
  'app.IMAGE': 'IMAGE',
  'app.MOVIE': 'MOVIE',
  'app.CERTIFICATE': 'CERTIFICATE',
  'app.PLOTTING': 'PLOTTING',
  'app.artName': 'Article Name',
  'app.AddArticle': 'Add Article',
  'app.ListArticle': 'List Article',
  'app.UpdateArticle': 'Update Article',
  'app.select-user-type': 'Select User Type',
  'app.no-recent-activities': 'No recent activity found',
  'app.vendor-code': 'Vendor Code',
  'app.vendor-code2': 'Code',
  'app.loading': 'Loading...',
  'app.AddEnquiry': 'Add Enquiry',
  'app.buyRequest': 'Add Buy Request',
  'app.seller': 'Seller',
  'app.salesenq': 'My Sales Enquiry',
  'app.buyenq': 'Buy Enquiry',
  'app.FilterByDate': 'Filter By Date',
  'app.giveFeedback': 'Give Feedback',
  'app.logoutConfirmation': 'Are you sure you want to log out?',
  'app.description': 'Description',
  'app.foundedYear': 'Founded Date',
  'app.companySize': 'Company Size',
  'app.displayPublicly': 'Display company description on my public profile',
  'app.sharingLinks': 'Sharing Links',
  'app.feedbackQ1': 'What do you think about our portal?',
  'app.feedbackQ2': 'How likely are you to recommend our portal to a friend or collegue on a scale of 0-10?',
  'app.feedbackQ3': 'What other information whould you like to see on our portal?',
  'app.feedbackQ4': 'How would you rate our service on scale of 0-10?',
  'app.searchInventory': 'Search Inventory',
  'app.backToDashboard': 'Back To Dashboard',
  'app.uploadInventory': 'Upload Inventory',
  'app.locStoneReport': 'Location / StoneID / Report No.',
  'app.dwnload': 'Download',
  'app.refresh': 'Refresh',
  'app.info': 'Error Summary',
  'app.totalDiamonds': 'Total Diamonds',
  'app.totalSuppliers': 'Total Registrations',
  'app.totalCarat': 'Total Carat',
  'app.totalValue': 'Total Value',
  'app.instructions': 'Instructions For Upload Inventory',
  'app.prefferedInfo': 'Preferred image size is 160*160px.',
  'app.sellerDetail': 'Seller Detail',
  'app.feedbackTitle': 'feel free to drop us a line and give us your feedback',
  'app.thankyouTitle': 'Thank You For Fill Feedback Form',
  'app.home': 'Home',
  'app.department': 'Department',
  'app.Article': 'Article',
  'app.ManufacturingFacility': 'Manufacturing Facility',
  'app.Locations': 'Locations',
  'app.headQuarters': 'Headquarter',
  'app.memberProfile': 'Member Profile',
  'app.certified': 'Certified',
  'app.non-certified': 'Non certified',
  'app.parcel': 'Parcel',
  'app.visit-website': 'Visit Website',
  'app.explore-inventory': 'Explore Inventory',
  'app.facebook': 'Facebook',
  'app.pinterest': 'Pinterest',
  'app.instagram': 'Instagram',
  'app.addNeFile': 'Add',
  'app.edit': 'Edit',
  'app.removeFile': 'Remove File',
  'app.reload-sheet': 'Reload Sheet',
  'app.upload-via': 'Upload via',
  'app.diamondsUploaded': 'Diamonds Uploaded',
  'app.req-for-FTP': 'Request For FTP',
  'app.host': 'Host',
  'app.port': 'Port',
  'app.socialLinks': 'Social Links',
  'app.coverImage': 'Cover Image',
  'app.removeCover': 'Remove Cover Image',
  'app.changeCover': 'Change Cover Image',
  'app.preferCoverSize': 'Preferred image size is 1500*312px',
  'app.changeFile': 'Change File',
  'app.mapped': 'Mapped',
  'app.notMapped': 'Not Mapped',
  'app.fileName': 'File Name',
  'app.follow': 'Follow',
  'app.un-follow': 'Unfollow',
  'app.followers': 'followers',
  'app.follower': 'follower',
  'app.searchByName': 'Search by Name / Id / Vendor Code / Business Type',
  'app.searchLocation': 'Search Location',
  'app.todaysAddedDiamonds': "Today's Added Diamonds",
  'app.following': 'Following',
  'app.confirmation': 'Confirmation',
  'app.doyouwantto': 'Do you want to unfollow',
  'app.followingOnly': 'Following Only',
  'app.events': 'Events',
  'app.remark': 'Remark',
  'app.add-more-param': 'Add with more parameters',
  'app.till-date': 'Required till date',
  'app.learnMore': 'Learn More',
  'app.showMore': 'Show More',
  'app.finestardiamod': 'DiamNow Diamond',
  'app.termsOfUse': 'TERMS OF USE',
  'app.privacyNotice': 'PRIVACY POLICY',
  'app.madeinbotswana': 'Made In Botswana',
  'app.StockSearch': 'Stock Search',
  'app.MatchingPair': 'Matching Pair',
  'app.NonCertifiedStones': 'Non Certified Stones',
  'app.QuoteList': 'Quote List',
  'app.DORInventory': 'DOR Inventory',
  'app.ForevermarkInventory': 'Forevermark Inventory',
  'app.WatchList': 'Watchlist',
  'app.OfficeViewList': 'Office View List',
  'app.CanadamarkInventory': 'Canadamark Inventory',
  'app.CartList': 'Cart List',
  'app.DiamNowEmerald': 'DiamNow Emerald',
  'app.TrackYourShipment': 'Track Your Shipment',
  'app.DOREligible': 'DOR Eligible',
  'app.ForevermarkEligible': 'Forevermark Eligible',
  'app.justBidIt': 'Just Bid It',
  'app.LookNBid': 'Look & Bid',
  'app.BlindBid': 'Blind Bid',
  'app.InManufacturing': 'In Manufacturing',
  'app.YourAccount': 'Your Account',
  'app.Number': 'Number',
  'app.Time': 'Time',
  'app.SeeYourProfile': 'See your Profile',
  'app.SignOut': 'Sign Out',
  'app.MyProfile': 'My Profile',
  'app.confirmStone': 'Request To Buy',
  'app.Watch': 'Watch',
  'app.More': 'More',
  'app.Quote': 'Quote',
  'app.Value': 'Value :',
  'app.PriceNDisc': 'Price & Disc %',
  'app.PricePerCarat': 'Price / Carat',
  'app.Stage': 'Stage',
  'app.Disc%': 'Disc %',
  'app.PacketNo': 'Packet No.',
  'app.PacketNPair%': 'Packet / Pair No.',
  'app.WhiteInclusion': 'White Inclusion',
  'app.BlackInclusion': 'Black inclusion',
  'app.CenterNatts': 'Center Natts',
  'app.SideNatts': 'Side Natts',
  'app.Center': 'Center',
  'app.Side': 'Side Inc',
  'app.Culet': 'Culet',
  'app.OpenOnTable': 'Open On Table',
  'app.OpenOnPavillion': 'Open On Pavillion',
  'app.OpenOnCrown': 'Open On Crown',
  'app.Girdle': 'Girdle',
  'app.Seal': 'Seal',
  'app.SelectGrouping': 'Select Grouping :',
  'app.DateWiseGrouping': 'Date Wise Grouping',
  'app.DiamondWiseGrouping': 'Diamond Wise Grouping',
  'app.PersonalInformation': 'Personal Information',
  'app.CompanyInformation': 'Company Information',
  'app.ChangePassword': 'Change Password',
  'app.ManageDiamondSequence': 'Manage Diamond Sequence',
  'app.EditProfile': 'Edit Profile',
  'app.RemoveProfile': 'Remove Profile',
  'app.FirstNameLabel': 'First Name *',
  'app.MiddleNameLabel': 'Middle Name',
  'app.LastNameLabel': 'Last Name *',
  'app.Address1Label': 'Address 1 *',
  'app.Address2Label': 'Address 2',
  'app.Address3Label': 'Address 3',
  'app.EmailLabel': 'Email *',
  'app.CountryLabel': 'Country *',
  'app.StateLabel': 'State *',
  'app.CityLabel': 'City *',
  'app.pinCodeLabel': 'Pin Code *',
  'app.WhatsAppLabel': 'WhatsApp',
  'app.MobileNoLabel': 'Mobile No *',
  'app.SkypeLabel': 'Skype',
  'app.notify.bid': 'Please select stone(stones) to bid.',
  'app.notify.confirm': 'Please select stone(stones) to confirm.',
  'app.notify.addToCart': 'Please select stone(stones) to add in cart.',
  'app.notify.addToWatch': 'Please select stone(stones) to add in watch.',
  'app.notify.addToReminder': 'Please select stone (stones) to add in reminder.',
  'app.notify.applyQuote': 'Please select stone(stones) to apply quote.',
  'app.notify.FinalCalc': 'Please select stone(stones) for final calculation.',
  'app.notify.Share': 'Please select stone(stones) to share.',
  'app.notify.Dwnld': 'Please select stone(stones) to download.',
  'app.notify.updateReminder': 'Please select stone(stones) to update reminder.',
  'app.notify.delete': 'Please select stone(stones) to delete.',
  'app.Cart.subTotal': 'Sub Total',
  'app.Cart.placeOrder': 'Place Order',
  'app.Remove': 'Remove',
  'app.SelectShape': 'Select Shape',
  'app.SelectColorRange': 'Select Color Range',
  'app.SelectClarityRange': 'Select Clarity Range',
  'app.SelectColor': 'Select Color',
  'app.SelectClarity': 'Select Clarity',
  'app.YourPrice': 'Your Price',
  'app.ListPrice': 'List Price',
  'app.AddSaveNSearch': 'Add Save & Search',
  'app.SearchTitle': 'Search Title',
  'app.SearchRecent': 'Search Recent',
  'app.Search': 'Search',
  'app.AddressLine1': 'Address Line 1',
  'app.AddressLine2': 'Address Line 2',
  'app.Country': 'Country',
  'app.SelectCountry*': 'Select Country *',
  'app.State': 'State',
  'app.SelectState*': 'Select State *',
  'app.City': 'City',
  'app.SelectCity*': 'Select City *',
  'app.ZipCode': 'Postal Code',
  'app.CompanyCode': 'Company Code ',
  'app.CompanyName*': 'Company Name *',
  'app.ZipCode*': 'ZipCode*',
  'app.SelectBusinessType': 'Select Business Type',
  'app.AddressLine1*': 'Address Line 1*',
  'app.Update': 'Update',
  'app.BRILLIANCE': 'Brilliance',
  'app.Total': 'Total',
  'app.bidStone': 'Bid Stone',
  'app.FinalCalculation': 'Final Calculation',
  'app.Office': 'Office',
  'app.CompareStone': 'Compare Stone',
  'app.Share': 'Share',
  'app.Download': 'Download',
  'app.PrintList': 'Print List',
  'app.Appointment': 'Appointment',
  'app.Home.FNSTRJNDPVTLTD': 'DiamNow',
  'app.Home.NewsNEvent': 'News & Event',
  'app.Home.BidProce': 'Bidding Procedure',
  'app.Home.DownloadKYC': 'Download KYC Form',
  'app.Home.TakeApntmt': 'Take Appointment',
  'app.Home.Terms': 'Terms',
  'app.Demo': 'Demo',
  'app.SelectMonth': 'Select Month',
  'app.VirtualAppointment': 'Virtual Appointment',
  'app.SelectVirtualType': 'Select Virtual Type',
  'app.AvailableRoom': 'Available Room',
  'app.RequestAppointment': 'Request Appointment',
  'app.Track': 'Track',
  'app.TrackStatus': 'Track Status',
  'app.BidEndIn': 'Bid End In',
  'app.Hours': 'Hours',
  'app.Minutes': 'Minutes',
  'app.Seconds': 'Seconds',
  'app.ManageAccount': 'Manage Account',
  'app.ManageDiamonds': 'Manage Diamonds',
  'app.Certificate': 'Certificate',
  'app.Image': 'Image',
  'app.Excel': 'Excel',
  'app.Video': 'Video',
  'app.KeyToSymbolReportComments': 'Key to Symbol / Report Comments',
  'app.OtherParameters': 'Other Parameters',
  'app.MyOfficeView': 'My Office View',
  'app.MyOfferList': 'My Quote List',
  'app.MyOrderedList': 'My Ordered List',
  'app.MyPurchaseHistory': 'My Purchase History',
  'app.NewArrivals': 'New Arrivals',
  'app.BuyNow': 'Buy Now',
  'app.Offer': 'Quote',
  'app.InclusionsNOtherParameters': 'Inclusions & Other Parameters',
  'app.seller-details': 'Salesperson Details',
  'app.DiamondSearch': 'Diamond Search',
  'app.OpenInclusion': 'Open Inclusion',
  'app.Brown': 'Brown',
  'app.MixTint': 'Mix Tint',
  'app.Natural': 'Natural',
  'app.LengthMM': 'Length (MM)',
  'app.WidthMM': 'Width (MM)',
  'app.DepthMM': 'Depth (MM)',
  'app.pavHt%': 'Pavilion Height %',
  'app.Stone': 'Stone',
  'app.InclusionPatterns': 'Inclusions',
  'app.myBid': 'My Bid',
  'app.DownloadZip.`': 'Download Zip',
  'app.Arrow': 'Arrow',
  'app.Asset': 'Asset',
  'app.Shade': 'Shade',
  'app.ReportNo': 'Report No',
  'app.ReportComments': 'Report Comments',
  'app.OtherInformation': 'Other Information',
  'app.RoughMine': 'Rough Mine',
  'app.CountryOfOrigin': 'Country Of Origin',
  'app.Type2Cert': 'Type 2 Certificate',
  'app.Brilliancy': 'Brilliancy',
  'app.Lab': 'Lab',
  'app.Measurements': 'Measurements',
  'app.Depth%': 'Depth %',
  'app.CenterWhite': 'Center White',
  'app.SideWhite': 'Side White',
  'app.SideBlack': 'Side Black',
  'app.SideOpen': 'Side Open',
  'app.TableOpen': 'Table Open',
  'app.CrownOpen': 'Crown Open',
  'app.PavilionOpen': 'Pavilion Open',
  'app.removeStone': 'Remove Stone',
  'app.updateStone': 'Update Stone',
  'app.offerNote':
    'Any stone/stones put in Watchlist is not kept on hold for you as it is available for other customers as well.',
  'app.quoteNote':
    "Quote stone will directly be confirmed if the price gets approved so we request you to be sure before quoting. Any stone(s) put in Offer list is not kept on hold for you as it is available for other customer's as well.",
  'app.confirmStoneNote':
    "1) The prices mentioned over here are fixed and hence not negotiable.\n2) The Grading, patterns & parameters mentioned on our website beyond GIA's grading is solely our perspective based on examinations conducted by our grading department and we do not hold ourself responsible for any conflicts in this regard.",
  'app.bidNote1': 'The additional',
  'app.bidNote2': 'on amount value has already been added in the final calculation.',
  'app.CancelBid': 'Cancel Bid',
  'app.Disclaimer': 'Disclaimer',
  'app.IAgree': 'I Agree',
  'app.Quit': 'Quit',
  'app.Note': 'Note',
  'app.ExcelExport': 'Excel Export',
  'app.Add': 'Add',
  'app.ApplyQuote': 'Apply Quote',
  'app.SaveQuote': 'Save Quote',
  'app.CancelQuote': 'Cancel Quote',
  'app.EmailPic': 'Email Pic',
  'app.EmailStock': 'Email Stock',
  'app.SelectTimeSlot': 'Select Time Slot',
  'app.CancelStone': 'Cancel Stone',
  'app.HoldStone': 'Hold Stone',
  'app.isLocatedIn': 'is currently located in the',
  'app.notAvail':
    'and will not be available for delivery in India. If you wish to confirm the stone, kindly get in touch with your respective Sales Person',
  'app.pandemicNotice':
    '**With the current scenario due to the Pandemic, any shipment, from any location, will take at least 7-10 working days.',
  'app.deliverIndia':
    'is currently located in India and for delivery in any other country apart from India will take at least 7-10 working days.',
  'app.rapindlr': 'Rap.($)',
  'app.Rapaport': 'Rapaport',
  'app.RapPrice': 'Rap Price',
  'app.QuoteDay': 'Quote Day',
  'app.CompareStoneNow': 'Compare More',
  'app.BidHistory': 'Bid History',
  'app.GoToBid': 'Go to Bid',
  'app.myHoldList': 'My Hold List',
  'app.Fluorescence': 'Fluorescence',
  'app.Ranges': 'Ranges',
  'app.Parameters': 'Parameters',
  'app.Finishing': 'Finishing',
  'app.couple-stone': 'Couple Stone',
  'app.feature-stone': 'Feature Stone',
  'app.special-stone': 'Special Stone',
  'app.upcoming-stone': 'Upcoming Stone',
};

export const messages = {
  ...MESSAGES.DEFAULT,
  ...MESSAGES[process.env.REACT_APP_ID],
};
