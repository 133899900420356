import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import { AuthService } from 'services/Auth';

import { useIsMobile, useStorage } from 'util/hooks';
import { loadable } from 'util/loadable';
import { isEmpty, logInfo } from 'util/utils';

import { IS_QUICK_SEARCH } from 'constants/Common';

const App = loadable(() => import('App'));

const DiamondSearch = loadable(() => import('containers/DiamondSearch'));
const Account = loadable(() => import('components/Account'));
const ChangePassword = loadable(() => import('components/Account/ChangePassword'));
const CompanyInformation = loadable(() => import('components/Account/CompanyInformation'));
const DiamondColumnListing = loadable(() => import('components/Account/ManageDiamondSequence'));
const MobileAccount = loadable(() => import('components/Account/MobileAccount'));
const PersonalInformation = loadable(() => import('components/Account/PersonalInformation'));
const Dashboard = loadable(() => import('components/Dashboard/index'));
const Compare = loadable(() => import('components/DiamondCompare'));
const DiamondDetail = loadable(() => import('components/DiamondDetail'));
const DiamondList = loadable(() => import('components/DiamondList'));
const BidList = loadable(() => import('components/DiamondList/BidList'));
const MyDemand = loadable(() => import('components/MyDemand/index'));
const NewArrivalBidIt = loadable(() => import('components/NewArrivalBidIt'));
const Notifications = loadable(() => import('components/Notifications'));
const Offer = loadable(() => import('components/Offer'));
const PrivacyNotice = loadable(() => import('components/PrivacyNotice'));
const QuickSearch = loadable(() => import('components/QuickSearch'));
const SavedSearch = loadable(() => import('components/SavedSearch'));
const TrackShipment = loadable(() => import('components/TrackShipment'));
const NotificationDropdown = loadable(() => import('components/common/Header/NotificationDropdown'));

function Routes(props) {
  const location = props.location;

  const [isMobile] = useIsMobile();
  const [permissions] = useStorage(`userPermissions`);
  const [token] = useStorage(`token`);

  const redirectPage = React.useCallback(() => {
    if (isEmpty(token)) return AuthService.signOut();
    if (isEmpty(permissions)) return AuthService.signOut();
    // if (isTrusted === 'false') return history.push('/verify-otp');
    // if (isDevice) return history.push('/');
  }, [permissions, token]);

  React.useEffect(() => {
    // setDeviceToken();
    redirectPage();
  }, [redirectPage]);

  React.useEffect(() => {
    void window?.drift?.on?.('campaign:dismiss', logInfo);
    void window?.drift?.api?.widget?.hide?.();
  }, []);

  return (
    <App>
      <Switch>
        {permissions?.DASHBOARD?.view && <Route exact path="/dashboard" component={Dashboard} />}
        {permissions?.SEARCH_LIST?.view && <Route exact path="/diamond-list" component={DiamondList} />}
        {permissions?.SAVE_SEARCH?.view && isMobile && (
          <Route exact path="/account/saved-search" component={SavedSearch} />
        )}
        {permissions?.DEMAND?.view && isMobile && <Route exact path="/account/my-demand" component={MyDemand} />}
        {permissions?.SHIPMENT?.view && isMobile && <Route exact path="/account/my-shipment" component={MyDemand} />}
        {IS_QUICK_SEARCH && permissions?.QUICK_SEARCH?.view && (
          <Route exact path="/quick-search" component={QuickSearch} />
        )}
        {isMobile && <Route exact path="/account/personal-information" component={PersonalInformation} />}
        {isMobile && <Route exact path="/account/company-profile" component={CompanyInformation} />}
        {isMobile && <Route exact path="/account/change-password" component={ChangePassword} />}
        {permissions?.COMPARE?.view && <Route exact path="/compare" component={Compare} />}
        {isMobile ? (
          <Route exact path="/account" component={MobileAccount} />
        ) : (
          <Route exact path="/account" component={Account} />
        )}
        <Route exact path="/diamond-detail/:id" component={DiamondDetail} />
        {permissions?.CART?.view &&
          (isMobile ? (
            <Route exact path="/account/cart" component={DiamondList} />
          ) : (
            <Route exact path="/account/cart" component={Account} />
          ))}
        {permissions?.HOLD?.view &&
          (isMobile ? (
            <Route exact path="/account/hold" component={DiamondList} />
          ) : (
            <Route exact path="/account/hold" component={Account} />
          ))}
        {permissions?.WATCHLIST?.view &&
          (isMobile ? (
            <Route exact path="/account/watchlist" component={DiamondList} />
          ) : (
            <Route exact path="/account/watchlist" component={Account} />
          ))}
        {permissions?.OFFICE?.view &&
          (isMobile ? (
            <Route exact path="/account/office-list" component={DiamondList} />
          ) : (
            <Route exact path="/account/office-list" component={Account} />
          ))}
        {permissions?.NOTES?.view &&
          (isMobile ? (
            <Route exact path="/account/notes" component={DiamondList} />
          ) : (
            <Route exact path="/account/notes" component={Account} />
          ))}
        {permissions?.QUOTE?.view &&
          (isMobile ? (
            <Route exact path="/account/quote-list" component={Offer} />
          ) : (
            <Route exact path="/account/quote-list" component={Account} />
          ))}
        {permissions?.CONFIRM_STONE?.view &&
          (isMobile ? (
            <Route exact path="/account/confirm-list" component={DiamondList} />
          ) : (
            <Route exact path="/account/confirm-list" component={Account} />
          ))}
        {permissions?.ENQUIRY?.view && <Route exact path="/account/enquiry" component={Account} />}
        {permissions?.SAVE_SEARCH?.view && <Route exact path="/account/saved-search" component={Account} />}
        {permissions?.DEMAND?.view && <Route exact path="/account/my-demand" component={Account} />}
        {permissions?.SHIPMENT?.view && <Route exact path="/account/my-shipment" component={Account} />}
        {permissions?.NOTIFICATIONS?.view && <Route exact path="/notifications" component={Notifications} />}
        {permissions?.REMINDER?.view && <Route exact path="/account/reminder" component={Account} />}
        <Route exact path="/account/profile" component={Account} />
        {isMobile ? (
          <Route exact path="/account/profile/diamond-sequence" component={DiamondColumnListing} />
        ) : (
          <Route exact path="/account/profile/diamond-sequence" component={Account} />
        )}
        {permissions?.MATCH_PAIR?.view && <Route exact path="/match-pair" component={DiamondList} />}
        {permissions?.UPCOMING?.view && <Route exact path="/upcoming" component={DiamondList} />}
        {permissions?.EXCLUSIVE?.view && <Route exact path="/special-stone" component={DiamondList} />}
        {permissions?.COLLECTION?.view && <Route exact path="/exclusive" component={DiamondList} />}
        {permissions?.QUOTE_DAY?.view && <Route exact path="/quote-day" component={DiamondList} />}
        {permissions?.SEARCH_MATCH_PAIR?.view && <Route path="/search-match-pair" component={DiamondSearch} />}
        {permissions?.SEARCH_DIAMOND?.view && (
          <Route path="/diamond-search/:id?" key={location.key} component={DiamondSearch} />
        )}
        {permissions?.FANCY_SEARCH?.view && (
          <Route path="/fancy-search/:id?" key={location.key} component={DiamondSearch} />
        )}
        <Route exact path="/privacy-notice" component={PrivacyNotice} />
        <Route exact path="/track-diamond" component={TrackShipment} />
        {isMobile && <Route exact path="/notification" component={NotificationDropdown} />}
        {/* {permissions?.BID?.view && <Route exact path="/search-bid-diamond" component={DiamondSearch} />} */}
        {/* {permissions?.BID?.view &&
        <Route exact path="/new-arrival-bid-it" component={NewArrivalBidIt} />
        } */}
        {permissions?.MY_BID?.view && <Route exact path="/my-bid" component={BidList} />}
        {permissions?.SUGGESTED_STOCK?.view && <Route exact path="/suggested-stock" component={DiamondList} />}
        {permissions?.STONE_OF_THE_DAY?.view && <Route exact path="/stone-of-the-day" component={DiamondList} />}
        <Redirect from="*" to="/not-found" />
      </Switch>
    </App>
  );
}

export default withRouter(Routes);
