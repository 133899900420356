import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import { history } from 'util/history';

import reducers from 'appRedux/reducers';

const routeMiddleware = routerMiddleware(history);

const compose = composeWithDevTools({ trace: true, traceLimit: 25 });

const middleware = [thunk, routeMiddleware];

export const store = createStore(reducers, compose(applyMiddleware(...middleware)));

void module?.hot?.accept?.('../reducers', () => store.replaceReducer(reducers));
