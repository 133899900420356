import { runInDevelopment } from 'util/utils';

import { LoaderActions } from 'appRedux/reducers/loader';
import { store } from 'appRedux/store';

export class LoaderService {
  static startLoading() {
    return store.dispatch(LoaderActions.start());
  }

  static stopLoading() {
    return store.dispatch(LoaderActions.stop());
  }

  static adjustCount(count) {
    return store.dispatch(LoaderActions.adjust({ count }));
  }

  static resetLoading() {
    return store.dispatch(LoaderActions.reset());
  }
}

runInDevelopment(() => void (window.__LoaderService = LoaderService));
