import antdHe from 'antd/lib/locale-provider/he_IL';
import appLocaleData from 'react-intl/locale-data/he';
import saMessages from '../locales/he_IL.json';

const heLang = {
  messages: {
    ...saMessages,
  },
  antd: antdHe,
  locale: 'he',
  data: appLocaleData,
};
export default heLang;
