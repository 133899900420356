import { ListUtils } from 'components/DiamondList/list-utils';

import { isArray, isEmpty, isNotEmpty, isObject, isString } from 'util/utils';

const initialState = {
  selectedRows: {},
  selectedRowIds: {},
};

export const ROW_SELECTION_ACTION_TYPES = {
  SELECT_ROW: 'SELECT_ROW',
  SELECT_ROWS: 'SELECT_ROWS',
  UNSELECT_ROW: 'UNSELECT_ROW',
  UNSELECT_ROWS: 'UNSELECT_ROWS',
  RESET_SELECTED_ROWS: 'RESET_SELECTED_ROWS',
};

export const ROW_SELECTION_ACTIONS = {
  selectRow: (payload = {}) => ({ type: ROW_SELECTION_ACTION_TYPES.SELECT_ROW, ...payload }),
  unSelectRow: (payload = {}) => ({ type: ROW_SELECTION_ACTION_TYPES.UNSELECT_ROW, ...payload }),
  selectRows: (payload = {}) => ({ type: ROW_SELECTION_ACTION_TYPES.SELECT_ROWS, ...payload }),
  unSelectRows: (payload = {}) => ({ type: ROW_SELECTION_ACTION_TYPES.UNSELECT_ROWS, ...payload }),
  resetSelectedRows: (payload = {}) => ({ type: ROW_SELECTION_ACTION_TYPES.RESET_SELECTED_ROWS, ...payload }),
};

const getFormattedPayload = (state, action) => {
  const { type, uniqueBy, ...data } = action;
  const currentType = isString(data?.currentType) ? data?.currentType : undefined;
  const currentRows = data?.replace ? [] : state?.selectedRows?.[currentType] ?? [];
  const currentRowIds = data?.replace ? [] : state?.selectedRowIds?.[currentType] ?? [];
  const payload = isObject(data?.payload) ? [data?.payload] : isArray(data?.payload) ? data?.payload : [];

  return { currentType, currentRows, currentRowIds, payload, uniqueBy };
};

export default function rowSelection(state = initialState, action) {
  switch (action.type) {
    case ROW_SELECTION_ACTION_TYPES.SELECT_ROW:
    case ROW_SELECTION_ACTION_TYPES.SELECT_ROWS: {
      const { currentType, currentRows, currentRowIds, payload, uniqueBy } = getFormattedPayload(state, action);

      if (isString(uniqueBy) && payload.length === 1) {
        const found = currentRows.find((row) => row?.uniqueBy === payload?.[0]?.uniqueBy);
        if (!isEmpty(found)) return state;
      }

      const newRows = payload
        .map((row) => (!currentRowIds.includes(row?.selectionKey ?? row?.id) ? ListUtils.filterRecord(row) : undefined))
        .filter(isNotEmpty);
      const newRowIds = newRows.map((row) => row?.selectionKey ?? row?.id);
      newRowIds.forEach((id) => ListUtils.updateRowClassList(currentType, id, true));

      return {
        ...state,
        selectedRows: { ...state?.selectedRows, [currentType]: [...currentRows, ...newRows] },
        selectedRowIds: { ...state?.selectedRowIds, [currentType]: [...currentRowIds, ...newRowIds] },
      };
    }

    case ROW_SELECTION_ACTION_TYPES.UNSELECT_ROW:
    case ROW_SELECTION_ACTION_TYPES.UNSELECT_ROWS: {
      const { currentType, currentRows, payload } = getFormattedPayload(state, action);

      const rowIdsToRemove = payload.map((row) => row?.selectionKey ?? row?.id);
      rowIdsToRemove.forEach((id) => ListUtils.updateRowClassList(currentType, id, false));

      const newRows = currentRows.filter((row) => !rowIdsToRemove.includes(row?.selectionKey ?? row?.id));
      const newRowIds = newRows.map((row) => row?.selectionKey ?? row?.id);

      return {
        ...state,
        selectedRows: { ...state?.selectedRows, [currentType]: newRows },
        selectedRowIds: { ...state?.selectedRowIds, [currentType]: newRowIds },
      };
    }

    case '@@RESET':
    case ROW_SELECTION_ACTION_TYPES.RESET_SELECTED_ROWS: {
      const { currentType } = getFormattedPayload(state, action);
      ListUtils.updateRowClassList(currentType);
      if (!isEmpty(currentType)) {
        delete state?.selectedRows?.[currentType];
        delete state?.selectedRowIds?.[currentType];
        return { ...state, selectedRows: { ...state?.selectedRows }, selectedRowIds: { ...state?.selectedRowIds } };
      }
      return { ...initialState };
    }

    default:
      return state;
  }
}
