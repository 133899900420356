import { ListConfig } from 'components/DiamondList/list-config';

import {
  catchError,
  forEach,
  formatCurrency,
  formatDecimal,
  formatNumber,
  isArray,
  isEmpty,
  isNotEmpty,
  isObject,
  logWarn,
} from 'util/utils';

import { FRACTION_LENGTH } from 'constants/Common';

export class ListUtils {
  static formatValue(value, key) {
    const isFloat = ListConfig.FLOAT_COLUMNS.includes(key);
    const isRound = ListConfig.ROUND_COLUMNS.includes(key);
    const isCurrency = ListConfig.CURRENCY_COLUMNS.includes(key);

    if (isEmpty(value) || value === 0) return '-';
    if (isCurrency)
      return formatCurrency(value, { fractionLength: isFloat ? FRACTION_LENGTH : isRound ? 0 : undefined });
    if (isFloat) return formatDecimal(value);
    if (isRound) return formatNumber(Math.round(value));
    return value;
  }

  static updateRecord(record) {
    if (!isObject(record)) return;
    record._original = { ...(record?._original ?? record) };

    record.colNm = record?.isFcCol ? record?.fcColDesc : record?.colNm;

    const output = Object.fromEntries(
      Object.entries(record).map(([key, value]) => [key, this.formatValue(value, key)]),
    );

    return output;
  }

  static updateList(list, inBlockList = [], options = {}) {
    if (!isArray(list)) return;
    return list.map((record) => this.updateRecord(record, inBlockList, options));
  }

  static filterRecord(record, remove = []) {
    if (!isObject(record)) return;
    record = { ...record, ...record?._original };
    const byUpdater = [];
    const byGroupping = ['isFooter', 'isHeader', 'groupData', 'totalDiamonds', '_groupBy_'];
    const byGroupType = ['isHoldHeader', 'isMatchHeader', 'userGroupHold'];
    const keysToDelete = [...byUpdater, ...byGroupping, ...byGroupType, ...remove];
    keysToDelete.forEach((key) => delete record[key]);
    return record;
  }

  static filterList(list, remove = []) {
    if (!isArray(list)) return;
    return list.map((record) => this.filterRecord({ ...record }, remove));
  }

  static getStickyStyle(noStatus = false) {
    const stickyHeader = { position: 'sticky', zIndex: 2000 };
    const stickyCell = { position: 'sticky', backgroundColor: '#fff', zIndex: 1000 };
    const firstWidth = noStatus ? 40 : 140;
    const secondWidth = 160;
    const firstLeft = -10;
    const secondLeft = firstWidth + firstLeft - 5;
    const thirdLeft = firstWidth + secondWidth + firstLeft - 10;

    return {
      head: {
        first: { left: firstLeft + 'px', ...stickyHeader, minWidth: firstWidth + 'px' },
        second: { left: secondLeft + 'px', ...stickyHeader, minWidth: secondWidth + 'px' },
        third: { left: thirdLeft + 'px', ...stickyHeader },
      },
      cell: {
        first: { left: firstLeft + 'px', ...stickyCell, minWidth: firstWidth + 'px' },
        second: { left: secondLeft + 'px', ...stickyCell, minWidth: secondWidth + 'px' },
        third: { left: thirdLeft + 'px', ...stickyCell },
      },
    };
  }

  static getGroupTitle(record = {}, options = {}) {
    const dateTitle = record?.dateTitle ?? '';
    const country = record?.countryNm ?? '-';
    const salesPerson = record?.seller ?? '-';
    const date = record?.userDate ?? record?.createdAt ?? '-';

    const user = [
      record?.user?.account?.companyName ?? record?.user?.companyName,
      record?.userName,
      record?.userMobile,
      record?.userEmail,
    ]
      .filter(isNotEmpty)
      .join(' | ');

    return options?.user && options?.date
      ? `${dateTitle} ${date} | ${user}`
      : options?.sortStone
      ? record?.vStnId ?? '-'
      : options?.user
      ? user
      : options?.date
      ? `${dateTitle} ${date}`
      : options?.country
      ? country
      : options?.salesPerson
      ? salesPerson
      : options?.stageShow
      ? `${record?.blockCode ?? '-'}`
      : options?.orderShow
      ? `${record?.memoNo ?? '-'}`
      : `${dateTitle} ${date} | ${user}`;
  }

  static filterGridColumns(columns = [], options = {}) {
    columns =
      options?.dateList && options?.customerList
        ? columns.filter((el) => el.id !== 'createdAt' && el.id !== 'user')
        : options?.dateList
        ? columns.filter((el) => el.id !== 'createdAt')
        : options?.customerList
        ? columns.filter((el) => el.id !== 'user')
        : columns;

    if (options?.inClient) columns = columns.filter((el) => el.id !== 'user');

    return columns;
  }

  static updateRowClassList(accessor, rowId, select = false) {
    catchError(
      () => {
        const selector =
          !isEmpty(accessor) && !isEmpty(rowId)
            ? `table.${accessor}-table tr[table-row${accessor ? `="${accessor}${rowId}"` : ''}]`
            : !isEmpty(accessor)
            ? `table.${accessor}-table tr[table-row]`
            : undefined;

        const tableRows = document.querySelectorAll(selector);

        select
          ? forEach(tableRows, (row) => row.classList.add('selectTr'))
          : forEach(tableRows, (row) => row.classList.remove('selectTr'));
      },
      (error) => logWarn({ updateRowClassListError: error }),
    );
  }
}
