import { Storage } from 'services/Storage';

import { callApi } from 'util/CallAPI';
import { isEmpty, getUserName } from 'util/utils';

import { CREATE_ANALYTICS, GET_TIME as GET_SERVER_TIME, NEXT_BID as BID_CONFIG } from 'constants/apiConstant';

const UtilService = {
  callApi,
  getFullName: getUserName,
  getLocalStorageItem: (key) => Storage.get(key),
  setLocalStorageItem: (value, key) => Storage.set(key, value),
};

export const track = (data) => {
  if (isEmpty(Storage.get('token'))) return;
  const [page, section, action] = data.split('/');
  return callApi({ ...CREATE_ANALYTICS, request: { page, section, action } });
};

export const getCurrentTime = async () => {
  const [err, res] = await callApi(GET_SERVER_TIME);
  return !err ? res?.data : undefined;
};

export const getBidConfig = async () => {
  const [err, res] = await callApi({ ...BID_CONFIG });
  return !err ? res?.data : undefined;
};

export default UtilService;
