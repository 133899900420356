import { Storage } from 'services/Storage';

import { axios } from 'util/Api';
import { history } from 'util/history';
import { logInfo } from 'util/utils';

import { USER_LOGOUT } from 'constants/apiConstant';

import { store } from 'appRedux/store';

import { LoaderService } from './LoaderService';

export class AuthService {
  static async signOut() {
    LoaderService.startLoading();

    await AuthService.signOutRequest();

    Storage.deleteAll();
    store.dispatch({ type: '@@RESET' });

    history.push('/signin');
    LoaderService.stopLoading();
  }

  static async signOutRequest(token) {
    try {
      token = token ?? Storage.get('token');
      await axios.request({ ...USER_LOGOUT, headers: { Authorization: `JWT ${token}` } });
    } catch (error) {
      logInfo({ error });
    }
  }
}

window.__AuthService = AuthService;
