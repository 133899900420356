import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import Auth from './Auth';
import Commonm from './Common';
import DashData from './DashData';
import DeleteReminder from './deleteReminder';
import loader from './loader';
import Diamond from './row-select';
import setTimer from './timer';

const reducers = combineReducers({
  loader,
  routing: routerReducer,
  auth: Auth,
  commonData: Commonm,
  diamondData: Diamond,
  deleteReminder: DeleteReminder,
  setTimer: setTimer,
  sales: DashData,
});

export default reducers;
