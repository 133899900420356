import { AuthService } from 'services/Auth';
import { LoaderService } from 'services/LoaderService';
import { Storage } from 'services/Storage';

import { axios } from 'util/Api';
import { catchError, isEmpty, isFunction, logWarn, objectToQueryString } from 'util/utils';

import { BASE_URL } from 'constants/Common';

const handleuploadProgress = (progress) => {
  const loaded = Math.round((progress?.loaded * 100) / progress?.total);
  const showProgressDoc = document.querySelector('.api-loader .showProgress span');
  const showProgressMain = document.querySelector('.api-loader .showProgress');
  if (showProgressDoc) {
    if (showProgressMain?.classList?.contains?.('display-none')) {
      showProgressMain.classList.remove('display-none');
    }
    showProgressDoc.innerText = `${loaded}%`;
    showProgressDoc.style.width = `${loaded}%`;
  }
};

export const callApi = async (config, cb) => {
  if (config.showLoader) LoaderService.startLoading();

  const request = (() => {
    const token = config.auth ?? config.updateToken ?? Storage.get('token');

    return {
      baseURL: BASE_URL,
      url: config?.url,
      method: config?.method,
      data: config.payload ?? config.data ?? config?.request,
      headers: {
        ...(!isEmpty(token) && { Authorization: `JWT ${token}` }),
        ...(!config.isMultipart && { 'Content-Type': 'application/json' }),
        devicetype: config.deviceType ?? 1,
      },
      transformRequest: config?.isMultipart ? {} : undefined,
      onUploadProgress: config?.isMultipart ? handleuploadProgress : undefined,
    };
  })();

  const output = { error: undefined, response: undefined, data: undefined };
  try {
    const response = await axios.request(request);
    output.response = response;
    output.data = response?.data;
    output.error = response?.data?.code !== 'OK';
  } catch (error) {
    logWarn(error);
    output.response = error?.response;
    output.data = error?.response;
    output.error = error;
  }

  if (config.showLoader) LoaderService.stopLoading();
  if (output?.response?.code === 'E_UNAUTHORIZED') AuthService.signOut();
  if (isFunction(cb)) catchError(() => cb(output.error, output.data));

  return config.returnResponse ? output : [output.error, output.data];
};

export const withURLParams = (input, params = []) => {
  return { ...input, url: `${input.url}${params.join('/')}` };
};

export const withQueryParams = (input, params = {}) => {
  return { ...input, url: `${input.url}${objectToQueryString(params)}` };
};
